import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import '../scss/Choose.scss';

const Choose = () => {
    return (
        <div className='choose_container'>
            <Link to={"/ios"}><Button variant="contained" color="primary" size="large">IOS</Button></Link>
            <Link to={"/android"}><Button variant="contained" color="success" size="large">ANDROID</Button></Link>
        </div>
    )
}

export default Choose