import { useEffect, useState } from "react";
import { Routes, Route } from 'react-router-dom';
import OperationSystem from "../enums/os";
import CategoryBig from "./CategoryBig";
import Home from "./Home";
import IBuild from "../interfaces/IBuild";
import Choose from "./Choose";
import IArticle from "../interfaces/IArticle";
import ICategory from "../interfaces/ICategory";
import TypeOfAnalyticsMess from "../enums/analytics";

interface IRequestObjectData {
    supportModulesData: {
        analyticsType: TypeOfAnalyticsMess
        path: string,
        articleId?: number | undefined
        articleTitle?: string | undefined
        queryBody?: string
    }
    getParams: any
}

const App = () => {

    const [apiDataAndroid, setApiDataAndroid] = useState<IBuild>();
    const [apiDataIOS, setApiDataIOS] = useState<IBuild>();
    const [allArticlesAndroid, setAllArticlesAndroid] = useState<IArticle[]>();
    const [allArticlesIos, setAllArticlesIos] = useState<IArticle[]>();
    const [getParamsState, setGetParamsState] = useState(new URLSearchParams(window.location.search));

    const template = [
        {
            id: 1,
            en: 'broadcasts',
            ru: 'Трансляции',
            categories: ['Трансляции']
        },
        {
            id: 2,
            en: 'friends',
            ru: 'Друзья и общение',
            categories: ['Друзья и общение', 'Эфир']
        },
        {
            id: 3,
            en: 'payments',
            ru: 'Платежи и покупки',
            categories: ['Платежи и покупки']
        },
        {
            id: 4,
            en: 'profile',
            ru: 'Профиль',
            categories: ['Профиль']
        },
    ]

    useEffect(() => {
        getApiData(OperationSystem.ANDROID);
        getApiData(OperationSystem.IOS);
    }, [])

    async function getApiData(os: number) {
        const userUid = getParamsState.get('uid');
        const apiUrl = `${process.env.REACT_APP_BACK_URL}api/articles/?os_id=${os}${(userUid && userUid !== '') ? `&uid=${userUid}` : ''}`;
        let response = await fetch(apiUrl);
        if (response.ok) {
            let responseJSON = await response.json();
            if (os === OperationSystem.ANDROID) {
                setApiDataAndroid(responseJSON);
                setAllArticlesAndroid(responseJSON.categories.map((obj: { articles: IArticle[], child: ICategory[] }) => {
                    const result: Array<IArticle> = [];
                    result.push(...obj.articles);
                    obj.child.forEach(subcategory => result.push(...subcategory.articles));
                    return result;
                }).flat(Infinity));
            }
            if (os === OperationSystem.IOS) {
                setApiDataIOS(responseJSON);
                setAllArticlesIos(responseJSON.categories.map((obj: { articles: IArticle[], child: ICategory[] }) => {
                    const result: Array<IArticle> = [];
                    result.push(...obj.articles);
                    obj.child.forEach(subcategory => result.push(...subcategory.articles));
                    return result;
                }).flat(Infinity));
            }
        }
    }

    function sendAnalytics(analyticsType: TypeOfAnalyticsMess, path: string, articleId?: number, articleTitle?: string, queryBody?: string) {
        const requestObjectData: IRequestObjectData = {
            supportModulesData: {
                analyticsType,
                path,
                articleId,
                articleTitle,
                queryBody,
            },
            getParams: {}
        };

        for (const [key, value] of getParamsState.entries()) {
            requestObjectData.getParams[key] = value
        }
        fetch(`${process.env.REACT_APP_BACK_URL}api/event`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify(requestObjectData)
        })
    }

    function appSendFormData(formData: any) {
        for (const [key, value] of getParamsState.entries()) {
            formData.append(key, value);
        }
        formData.append('getParams', getParamsState);
        fetch(`${process.env.REACT_APP_BACK_URL}api/form`, {
            method: 'POST',
            credentials: 'same-origin',
            body: formData
        })
    }

    function changeBirthDate(field: string, value: string) {
        fetch(`${process.env.REACT_APP_BACK_URL}api/birth`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uid: getParamsState.get('uid'),
                field: field,
                value: value
            })
        })
    }

    return (
        <Routes>
            <Route path="/android">
                <Route
                    index={true}
                    element={<Home
                        popular={apiDataAndroid?.favorite_articles}
                        allArticles={allArticlesAndroid}
                        expand={getParamsState.get('expand')}
                        supportData={apiDataAndroid?.supportData}
                        os={OperationSystem.ANDROID}
                        bundle_id={getParamsState.get('bundle_id')}
                        app_version={getParamsState.get('app_version')}
                        locale={getParamsState.get('locale')}
                        sendAnalytics={sendAnalytics}
                        appSendFormData={appSendFormData}
                        changeBirthDate={changeBirthDate} />} />
                {template.map((category) => {
                    return (
                        <Route
                            key={category.id}
                            index={false}
                            path={`${category.en}`}
                            element={<CategoryBig
                                title={category.ru}
                                os={OperationSystem.ANDROID}
                                expand={getParamsState.get('expand')}
                                supportData={apiDataAndroid?.supportData}
                                bundle_id={getParamsState.get('bundle_id')}
                                app_version={getParamsState.get('app_version')}
                                locale={getParamsState.get('locale')} sendAnalytics={sendAnalytics}
                                appSendFormData={appSendFormData} changeBirthDate={changeBirthDate}
                                imgName={category.en}
                                categories={apiDataAndroid?.categories.filter(oneCategory => category.categories.includes(oneCategory.name))} />} />
                    )
                })}
            </Route>
            <Route path="/ios">
                <Route
                    index={true}
                    element={<Home
                        popular={apiDataIOS?.favorite_articles}
                        allArticles={allArticlesIos}
                        expand={getParamsState.get('expand')}
                        supportData={apiDataIOS?.supportData}
                        os={OperationSystem.IOS}
                        bundle_id={getParamsState.get('bundle_id')}
                        app_version={getParamsState.get('client_version')}
                        locale={getParamsState.get('locale')}
                        sendAnalytics={sendAnalytics}
                        appSendFormData={appSendFormData}
                        changeBirthDate={changeBirthDate} />} />
                {template.map((category) => {
                    return (
                        <Route
                            key={category.id}
                            index={false}
                            path={`${category.en}`}
                            element={<CategoryBig
                                title={category.ru}
                                os={OperationSystem.IOS}
                                expand={getParamsState.get('expand')}
                                supportData={apiDataIOS?.supportData}
                                bundle_id={getParamsState.get('bundle_id')}
                                app_version={getParamsState.get('client_version')}
                                locale={getParamsState.get('locale')}
                                sendAnalytics={sendAnalytics}
                                appSendFormData={appSendFormData}
                                changeBirthDate={changeBirthDate}
                                imgName={category.en}
                                categories={apiDataIOS?.categories.filter(oneCategory => category.categories.includes(oneCategory.name))} />} />
                    )
                })}
            </Route>
            <Route path="*" element={<Choose />} />
        </Routes>
    )
}

export default App
