import '../scss/Category.scss'

type CategoryPropsType = {
    title: string
    imgName: string
}

const Category = (props: CategoryPropsType) => {
    return (
        <img
            src={require(`../images/categories/${props.imgName}.png`)}
            className='category-item'
            alt={props.title} />
    )
}

export default Category