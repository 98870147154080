import '../../scss/Popular.scss'
import '../../scss/CategoryBig.scss'

const AgeCase2 = () => {
    return (
        <div>
            <div className="zendesk-text">Вы можете самостоятельно раз в 7 дней изменять дату рождения в своем профиле, но не более чем на 3 года от установленной даты или через поддержку с предъявлением паспорта (будет нужна только фотография и дата рождения, остальное можно скрыть).</div>
        </div>
    )
}

export default AgeCase2