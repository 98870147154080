import { useState } from 'react';
import '../scss/Useful.scss';
import TypeOfAnalyticsMess from '../enums/analytics';
import { useLocation } from 'react-router-dom';

export type UsefulPropsType = {
    id: number
    title: string
    sendAnalytics: (analyticsType: TypeOfAnalyticsMess, path: string, articleId?: number, articleTitle?: string, queryBody?: string) => void  
}

const Useful = (props: UsefulPropsType) => {

    const [showNo, setShowNo] = useState(true);
    const [showUseful, setShowUseful] = useState(true);
    const [whyMode, setWhyMode] = useState(false);
    const [incomprehensible, setIncomprehensible] = useState(true);
    const [useless, setUseless] = useState(true);

    const location = useLocation();

    const yesHandler = () => {
        props.sendAnalytics(TypeOfAnalyticsMess.USEFUL_ARTICLE, location.pathname, props.id, props.title);
        setShowNo(false);
        setTimeout(closeUseful, 3000)
    }

    const noHandler = () => {
        setWhyMode(true);
    }

    const closeUseful = () => {
        setShowUseful(false);
    }

    const incomprehensibleHandler = () => {
        props.sendAnalytics(TypeOfAnalyticsMess.INCOMPREHENSIBLE_ARTICLE, location.pathname, props.id, props.title);
        setUseless(false);
        setTimeout(closeUseful, 3000)
    }

    const uselessHandler = () => {
        props.sendAnalytics(TypeOfAnalyticsMess.UNSOLVABLE_ARTICLE, location.pathname, props.id, props.title);
        setIncomprehensible(false);
        setTimeout(closeUseful, 3000)
    }


    return (
        showUseful ?
            <>
                {!whyMode ?
                    <div className="useful-container">
                        <div className="useful-text">
                            Была ли статья для вас полезной?
                        </div>
                        <div className="useful-buttons">
                            <div className={`useful-buttons-item ${!showNo ? 'timeout' : ''}`} onClick={yesHandler}>
                                <img className="useful-buttons-item-img" src={require(`../images/shared/happy${!showNo ? '_grey' : ''}.png`)} alt="Happy" />
                                <div className={`useful-buttons-item-text ${!showNo ? 'timeout' : ''}`}>Да</div>
                            </div>
                            {showNo &&
                                <div className="useful-buttons-item" onClick={noHandler}>
                                    <img className="useful-buttons-item-img" src={require(`../images/shared/sad.png`)} alt="Sad" />
                                    <div className="useful-buttons-item-text">Нет</div>
                                </div>
                            }
                        </div>
                    </div> :
                    <div>
                        <div className={`useful-container ${whyMode ? 'why' : ''}`}>
                            <div className="useful-text">
                                В чем проблема?
                            </div>
                            <div className={`useful-buttons ${whyMode ? 'why' : ''}`}>
                                {incomprehensible && <div className={`useful-buttons-item ${whyMode ? 'why' : ''}`}>
                                    <div className="useful-buttons-item-text" onClick={incomprehensibleHandler}>Информация непонятна</div>
                                </div>}
                                {useless && <div className={`useful-buttons-item ${whyMode ? 'why' : ''}`}>
                                    <div className="useful-buttons-item-text" onClick={uselessHandler}>Это не решает мою проблему</div>
                                </div>}
                            </div>
                        </div>
                    </div>
                }
            </>
            :
            <></>

    )
}



export default Useful
