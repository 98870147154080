import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import '../scss/Carousel.scss';
import IArticleImage from '../interfaces/IArticleImage';


type CarouselRatioPropsType = {
  imagesData: IArticleImage[]
}

export default function CarouselRatio(props: CarouselRatioPropsType) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        py: 1,
        overflow: 'auto',
        width: 310,
        scrollSnapType: 'x mandatory',
        '& > *': {
          scrollSnapAlign: 'center',
        },
        '::-webkit-scrollbar': { display: 'none' },
      }}
    >
      {props.imagesData?.map((item) => (
        <Card
          orientation="vertical"
          key={item.id}
          variant="outlined"
          sx={{
            gap: 1,
            maxWidth: 250,
            '--Card-padding': (theme) => theme.spacing(1),
          }}
        >
          <AspectRatio ratio="1/2" sx={{ minWidth: 250, borderRadius: '10px' }}>
            <img
              src={item.url}
              srcSet={item.url}
              alt={item.name}
            />
          </AspectRatio>
          <Box sx={{ whiteSpace: 'wrap', textAlign: 'center', margin: 'auto'}}>
            <Typography level="body2">{item.text}</Typography>
          </Box>
        </Card>
      ))}
    </Box>
  );
}