import '../../scss/Popular.scss'
import '../../scss/CategoryBig.scss'

const AgeCase3 = () => {
    return (
        <div>
            <div className="zendesk-text">Ранее вы подтвердили свой возраст, поэтому изменить его нельзя.</div>
        </div>
    )
}

export default AgeCase3