import '../../scss/Popular.scss';
import '../../scss/CategoryBig.scss';
import '../../scss/Date.scss';
import { useEffect, useState } from 'react';

type AgeCase1PropsType = {
    currentDate: string
    changeBirthDate: (field: string, value: string) => void
}

const AgeCase1 = (props: AgeCase1PropsType) => {

    const [dateValue, setDateValue] = useState<string>();
    const [canSendToDV, setCanSendToDV] = useState<boolean>(false);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [sendDone, setSendDone] = useState<boolean>(false);

    useEffect(() => {
        if (dateValue && checkChooseDate(dateValue)) {
            setCanSendToDV(true);
        } else {
            setCanSendToDV(false);
        }
    }, [dateValue]);

    const sendButtonHandler = () => {
        if (canSendToDV && dateValue && !sendDone) {
            props.changeBirthDate('BIRTH', dateValue);
            setTimeout(() => {
                setShowResult(true);
                setSendDone(true);
            }, 2000)
        }
    }

    function checkChooseDate(date: string): boolean {
        const getDateObj = new Date(date);
        const getDateObj2 = new Date(date);
        const currentDate = new Date();
        const add18YearsDate = getDateObj.setFullYear(getDateObj.getFullYear() + 18);
        const hundredYearsAgo = currentDate.setFullYear(currentDate.getFullYear() - 100);
        return add18YearsDate <= Date.now() && getDateObj2 >= new Date(hundredYearsAgo);
    }

    return (
        <div>
            <div className="zendesk-text">Вам доступно разовое изменение даты рождения в пределах совершеннолетнего возраста. После чего вы можете самостоятельно раз в 7 дней изменять дату рождения в своем профиле, но не более чем на 3 года от установленной даты. Также, дату рождения можно поменять через поддержку, подтвердив ее с помощью паспорта.
            </div>
            <div className='date_action_box'>
                {!showResult && <div className='input-container'>
                    <input
                        type="date"
                        value={dateValue}
                        defaultValue={props.currentDate.split('.').reverse().join('-')}
                        className="input-custom"
                        onChange={(event) => {
                            setDateValue(event.target.value);
                        }}
                    />
                    {dateValue && <img
                        className='input-icon-clear'
                        src={require(`../../images/shared/clear.png`)}
                        onClick={(event) => setDateValue('')} />}
                </div>}
                <div className={`feedback-actions-send ${!canSendToDV ? 'disable' : ''}`} onClick={sendButtonHandler}>
                    {!sendDone && <img src={require(`../../images/shared/send_${canSendToDV ? 'white' : 'grey'}.png`)} alt="Add photo" />}
                    <div className={`feedback-actions-send-text ${!canSendToDV ? 'disable' : ''}`}>{showResult ? 'Успешно' : 'Изменить'}</div>
                </div>
            </div>
        </div>
    )
}

export default AgeCase1