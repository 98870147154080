import '../../scss/Popular.scss'
import '../../scss/CategoryBig.scss'

const SexCase1 = () => {
    return (
        <div>
            <div className="zendesk-text">Пожалуйста, пришлите в поддержку, через поле ниже, селфи с точно таким же жестом, как на примере:</div>
            <div className='verification_img_container'>
                <img src={require(`../../images/sex_verification/${Math.ceil(Math.random() * 6)}.JPG`)} alt="verification img" />
            </div>
        </div>
    )
}

export default SexCase1