import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import '../scss/Feedback.scss';
import TypeOfAnalyticsMess from '../enums/analytics';
import { useLocation } from 'react-router-dom';


type FeedbackPropsType = {
    userId?: string
    id: number
    zenId: number
    title: string
    sendAnalytics: (analyticsType: TypeOfAnalyticsMess, path: string, articleId?: number, articleTitle?: string, queryBody?: string) => void
    appSendFormData: (formData: any) => void
    onlyPhoto?: boolean
}
const Feedback = (props: FeedbackPropsType) => {

    const [textAreaValue, setTextAreaValue] = useState<string>('');
    const [images, setImages] = useState<any>([]);
    const [canSend, setCanSend] = useState<boolean>(false);
    const [sended, setSended] = useState<boolean>(false);

    useEffect(() => {
        if (props.onlyPhoto) {
            images.length ? setCanSend(true) : setCanSend(false);
        } else {
            textAreaValue.trim() ? setCanSend(true) : setCanSend(false);
        }
    }, [textAreaValue, images])

    const fileInput = useRef<HTMLInputElement>(null);
    const location = useLocation();

    const handleMultipleImages = (event: any) => {
        const selectedFiles: any = [];
        const targetFiles = event.target.files;
        const targetFilesObject = [...targetFiles];

        targetFilesObject.map((file) => {
            return selectedFiles.push({
                source: file,
                blob: URL.createObjectURL(file)
            });
        });

        setImages(selectedFiles);
    }

    const textAreaHandler = (value: string) => {
        setTextAreaValue(value);
    }

    const removePreviewImg = (target: number) => {
        fileInput.current!.value = ''
        setImages([...images].filter((oneImage, index) => index !== target));
    }

    const sendHandler = () => {
        if (canSend) {
            setSended(true);

            const formData = new FormData();

            formData.append('article_id', `${props.zenId}`);
            formData.append('text', textAreaValue);

            if (images.length) {
                formData.append('file', images[0].source);
            }

            props.appSendFormData(formData);
            props.sendAnalytics(TypeOfAnalyticsMess.SUPPORT_TICKET, location.pathname, props.id, props.title);
            setTextAreaValue('');
        }
    }

    const formHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }

    return (
        sended ?
            <>
                <div className='sended'>
                    <span className='sended-text'>Ваш запрос отправлен</span>
                </div>
            </> :
            <>
                <form method='POST' encType='multipart/form-data' onSubmit={formHandler}>
                    <div className="feedback-container">
                        <div className="feedback-header">
                            <div className="feedback-text">
                                {props.onlyPhoto ? 'Прикрепите фото' : 'Задайте свой вопрос'}
                            </div>
                        </div>
                        <textarea
                            className="input-custom"
                            placeholder={props.onlyPhoto ? 'Комментарий' : 'Ваш вопрос'}
                            value={textAreaValue}
                            onChange={event => textAreaHandler(event.currentTarget.value)}
                        />
                        <div className="feedback-actions">
                            <IconButton style={{ color: '#00B8B8' }} aria-label="upload picture" component="label">
                                <input hidden accept="image/*" type="file" ref={fileInput} onChange={handleMultipleImages} />
                                <PhotoCamera />
                            </IconButton>
                            <div className={`feedback-actions-send ${!canSend ? 'disable' : ''}`} onClick={sendHandler}>
                                <img src={require(`../images/shared/send_${canSend ? 'white' : 'grey'}.png`)} alt="Add photo" />
                                <div className={`feedback-actions-send-text ${!canSend ? 'disable' : ''}`}>Отправить</div>
                            </div>
                        </div>
                        <div className='preview_container'>
                            {images?.map((file: { blob: string }, index: number) => {
                                return (
                                    <img key={index} src={file.blob} className='preview' onClick={() => removePreviewImg(index)} />
                                )
                            })}
                        </div>
                    </div>
                </form>
            </>
    )
}

export default Feedback
