import Category from "./Category";
import Popular from './Popular';
import Input from './Input';
import { Link, useLocation } from 'react-router-dom';
import IArticle from "../interfaces/IArticle";
import '../scss/Home.scss';
import { useEffect, useState } from "react";
import AllArticles from "./AllArticles";
import OperationSystem from "../enums/os";
import Header from "./Header";
import TypeOfAnalyticsMess from "../enums/analytics";
import ISupportData from "../interfaces/ISupportData";

type HomePropsType = {
    popular: IArticle[] | undefined
    allArticles: IArticle[] | undefined
    supportData?: ISupportData
    os: OperationSystem
    bundle_id: string | null
    locale: string | null
    app_version: string | null
    expand: string | null
    sendAnalytics: (analyticsType: TypeOfAnalyticsMess, path: string, articleId?: number, articleTitle?: string, queryBody?: string) => void
    appSendFormData: (formData: any) => void
    changeBirthDate: (field: string, value: string) => void
}

const Home = (props: HomePropsType) => {

    const [inputValue, setInputValue] = useState<string>('')
    const [activeSearch, setActiveSearch] = useState<boolean>(false)

    const inputFiltration = (value: string) => {
        setInputValue(value);
    }

    const location = useLocation()

    useEffect(() => {
        setActiveSearch(inputValue !== '');
    }, [inputValue])

    return (
        props.popular ?
            <div className="home-container">
                {window.location.hostname.split('.')[0] === 'qa' && <h3>QA SERVER</h3>}
                {/* <div className="home-container_header">
                <Header />
            </div> */}
                <Input placeholder="Поиск" value={inputValue} filtration={inputFiltration} />
                <div className="home-container_popular">
                    {!activeSearch ?
                        <Popular
                            articles={props.popular}
                            supportData={props.supportData}
                            bundle_id={props.bundle_id}
                            locale={props.locale}
                            app_version={props.app_version}
                            os={props.os} expand={props.expand}
                            sendAnalytics={props.sendAnalytics}
                            appSendFormData={props.appSendFormData}
                            changeBirthDate={props.changeBirthDate} /> :
                        <AllArticles
                            inputValue={inputValue}
                            supportData={props.supportData}
                            expand={props.expand}
                            allArticles={inputValue === '' ? props.allArticles : props.allArticles?.filter(article => article.title?.toLowerCase().includes(inputValue?.toLowerCase()) && article.title?.toLowerCase() !== 'другое')}
                            bundle_id={props.bundle_id}
                            locale={props.locale}
                            app_version={props.app_version}
                            os={props.os}
                            sendAnalytics={props.sendAnalytics}
                            appSendFormData={props.appSendFormData}
                            changeBirthDate={props.changeBirthDate} />}
                </div>
                {inputValue === '' && <div className="home-container_categories">
                    <Link to={`${location.pathname}/broadcasts`}><Category title={"Трансляции"} imgName={"broadcasts"} /></Link>
                    <Link to={`${location.pathname}/payments`}><Category title={"Платежи и покупки"} imgName={"payments"} /></Link>
                    <Link to={`${location.pathname}/friends`}><Category title={"Друзья и общение"} imgName={"friends"} /></Link>
                    <Link to={`${location.pathname}/profile`}><Category title={"Профиль"} imgName={"profile"} /></Link>
                </div>}
            </div> : <></>
    )
}

export default Home