import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useLocation } from 'react-router-dom';
import '../scss/Back.scss';
import OperationSystem from '../enums/os';

type BackPropsType = {
    os: OperationSystem
}

const Back = (props: BackPropsType) => {

    return (
        <div className='back-container'>
            <Link to={`/${props.os === 1 ? 'android' : 'ios'}`}><ArrowBackIosIcon color='success' /></Link><span>Назад</span>
        </div>
    )
}

export default Back