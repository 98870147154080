import '../../scss/Popular.scss'
import '../../scss/CategoryBig.scss'

type SexCase3PropsType = {
}

const SexCase3 = (props: SexCase3PropsType) => {
    return (
        <div className="zendesk-text">В вашем профиле назначена верификация, пройдите ее, после чего верный пол установится автоматически. Для этого отправьте запрошенное фото в чат «Подтверждение личности», он находится в списке ваших диалогов.</div>
    )
}

export default SexCase3