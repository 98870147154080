import IArticle from "../interfaces/IArticle"
import Category from "./Category";
import { Link, useLocation } from 'react-router-dom';
import '../scss/Popular.scss'
import Article from "./Article";
import TypeOfAnalyticsMess from "../enums/analytics";
import { useEffect } from "react";
import ISupportData from "../interfaces/ISupportData";
import OperationSystem from "../enums/os";

type AllArticlesPropsType = {
    inputValue: string
    allArticles: IArticle[] | undefined
    supportData?: ISupportData
    bundle_id: string | null
    locale: string | null
    app_version: string | null
    os: OperationSystem
    expand: string | null
    sendAnalytics: (analyticsType: TypeOfAnalyticsMess, path: string, articleId?: number, articleTitle?: string, queryBody?: string) => void
    appSendFormData: (formData: any) => void
    changeBirthDate: (field: string, value: string) => void
}

const AllArticles = (props: AllArticlesPropsType) => {
    const location = useLocation();
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!props.allArticles?.length) {
                props.sendAnalytics(TypeOfAnalyticsMess.SEARCH_QUERY, location.pathname, undefined, undefined, props.inputValue);
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [props.inputValue]);

    return (
        <>
            {props.allArticles && props.allArticles.length > 0 ?
                props.allArticles?.map(article => (
                    <Article
                        key={article.id}
                        id={article.id}
                        dv_id={article.dv_id}
                        title={article.title}
                        body={article.body}
                        expand={props.expand}
                        article_images={article.article_images}
                        automatization_tag={article.automatization_tag}
                        supportData={props.supportData}
                        locale={props.locale}
                        os={props.os}
                        app_version={props.app_version}
                        allow_send={article.allow_send}
                        bundle_id={props.bundle_id}
                        sendAnalytics={props.sendAnalytics}
                        appSendFormData={props.appSendFormData}
                        changeBirthDate={props.changeBirthDate} />
                ))
                :
                <>
                    <div className="info">По вашему запросу ничего не найдено</div>
                    <div className="home-container_categories">
                        <Link to={`${location.pathname}/broadcasts`}><Category title={"Трансляции"} imgName={"broadcasts"} /></Link>
                        <Link to={`${location.pathname}/payments`}><Category title={"Платежи и покупки"} imgName={"payments"} /></Link>
                        <Link to={`${location.pathname}/friends`}><Category title={"Друзья и общение"} imgName={"friends"} /></Link>
                        <Link to={`${location.pathname}/profile`}><Category title={"Профиль"} imgName={"profile"} /></Link>
                    </div>
                </>
            }
        </>
    )
}


export default AllArticles