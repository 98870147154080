import IArticle from "../interfaces/IArticle";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Useful from './Useful';
import CarouselRatio from "./Carousel";
import Feedback from "./Feedback";
import '../scss/Popular.scss'
import '../scss/CategoryBig.scss'
import TypeOfAnalyticsMess from "../enums/analytics";
import { useLocation } from "react-router-dom";
import Balance from "./automatization/Balance";
import ISupportData from "../interfaces/ISupportData";
import SexCase2 from "./automatization/SexCase2";
import SexCase3 from "./automatization/SexCase3";
import SexCase1 from "./automatization/SexCase1";
import AgeCase3 from "./automatization/AgeCase3";
import AgeCase2 from "./automatization/AgeCase2";
import AgeCase1 from "./automatization/AgeCase1";
import { useEffect, useState } from "react";
import OperationSystem from "../enums/os";

type AutomatizationTagType = 'hw_balance' | 'hw_sex' | 'hw_age'

const Article = (props: IArticle) => {
    const location = useLocation();
    const [expand, setExpand] = useState<boolean>(false);
    useEffect(() => {
        if (props.automatization_tag && (props.expand === props.automatization_tag)) {
            setExpand(true);
            setTimeout(() => {
                document.querySelector('.Mui-expanded')?.scrollIntoView({ behavior: 'smooth' });
            }, 500)
        }
    }, []);

    function adultUser(date: string): boolean {
        const dateParams = date.split('.');
        if (dateParams) {
            const mutateDate = dateParams.map((value, index) => {
                if (index === 2) {
                    value = `${+value + 18}`;
                }
                return value;
            })
            if (mutateDate) {
                const correctDate = `${mutateDate[2]}-${mutateDate[1]}-${mutateDate[0]}`;
                return new Date(correctDate) <= new Date();
            }
        }
        return false
    }

    function checkCorrectOSVersion(os: OperationSystem | null, app_version: string | null): boolean {
        const pattern = /([0-9]+)\.([0-9]+)\.([0-9]+)/gm;
        const substring = app_version?.match(pattern);
        if (substring) {
            const versionDigits = substring[0].split('.');
            let majorVersion, minorVersion, patch;
            if (versionDigits?.length === 3) {
                majorVersion = +versionDigits[0];
                minorVersion = +versionDigits[1];
                patch = +versionDigits[2];
                switch (os) {
                    case 1:
                        return majorVersion > 5 || (majorVersion === 5 && minorVersion > 5) || (majorVersion === 5 && minorVersion === 5 && patch >= 2)
                    case 2:
                        return majorVersion > 3 || (majorVersion === 3 && minorVersion > 17) || (majorVersion === 3 && minorVersion === 17 && patch >= 1)
                }
            }
        }
        return false
    }

    function chooseAutomatizationArticle(automatization_tag: AutomatizationTagType, supportData?: ISupportData) {

        // Автоматизация статьи пополнения баланса
        // if (supportData?.country === 'RU' || (automatization_tag && automatization_tag === 'hw_balance' && supportData?.country === 'RU' && (supportData.donate_rating >= 5 || supportData.earnings_rating >= 10))) {
        // if (automatization_tag && automatization_tag === 'hw_balance' && supportData?.country === 'RU' && props.locale === 'ru' && supportData?.has_payment_link && checkCorrectOSVersion(props.os, props.app_version)) {
        if (automatization_tag && automatization_tag === 'hw_balance' && supportData?.has_payment_link && checkCorrectOSVersion(props.os, props.app_version)) {
            return (
                <>
                    <Balance jwtToken={supportData.web_payment_token} bundle_id={props.bundle_id} os={props.os} app_version={props.app_version}  />
                    <Feedback id={props.id} zenId={props.dv_id} title={props.title} sendAnalytics={props.sendAnalytics} appSendFormData={props.appSendFormData} />
                </>
            )
        }

        // Автоматизация статей смены пола
        if (automatization_tag && automatization_tag === 'hw_sex' && supportData && checkCorrectOSVersion(props.os, props.app_version)) {
            if (!supportData.sex_changed_by_support && supportData.verification_status === 'NONE') {
                return (
                    <>
                        <SexCase1 />
                        <Feedback id={props.id} zenId={props.dv_id} title={props.title} sendAnalytics={props.sendAnalytics} appSendFormData={props.appSendFormData} onlyPhoto />
                        <Useful id={props.id} title={props.title} sendAnalytics={props.sendAnalytics} />
                    </>
                )
            }
            if (supportData.sex_changed_by_support || supportData.verification_status === 'COMPLETED') {
                return (
                    <>
                        <SexCase2 />
                        <Useful id={props.id} title={props.title} sendAnalytics={props.sendAnalytics} />
                    </>
                )
            }
            if (supportData.verification_status === 'REQUESTED' || supportData.verification_status === 'ISOLATED' || supportData?.verification_status === "DECLINED") {
                return (
                    <>
                        <SexCase3 />
                        <Useful id={props.id} title={props.title} sendAnalytics={props.sendAnalytics} />
                    </>
                )
            }
        }

        // Автоматизация статей смены возраста
        if (automatization_tag && automatization_tag === 'hw_age' && supportData && checkCorrectOSVersion(props.os, props.app_version)) {
            if (supportData.birth_date_change_enabled_on_support_page && adultUser(supportData.birth_date)) {
                return (
                    <>
                        <AgeCase1 changeBirthDate={props.changeBirthDate} currentDate={supportData.birth_date} />
                    </>
                )
            }
            if (supportData.support_checked_passport) {
                return (
                    <>
                        <AgeCase3 />
                        <Useful id={props.id} title={props.title} sendAnalytics={props.sendAnalytics} />
                    </>
                )
            }
            if (!supportData.birth_date_change_enabled_on_support_page && adultUser(supportData.birth_date)) {
                return (
                    <>
                        <AgeCase2 />
                        <Feedback id={props.id} zenId={props.dv_id} title={props.title} sendAnalytics={props.sendAnalytics} appSendFormData={props.appSendFormData} />
                        <Useful id={props.id} title={props.title} sendAnalytics={props.sendAnalytics} />
                    </>
                )
            }
        }

        return (
            <>
                <span dangerouslySetInnerHTML={{ __html: props.body }} className="zendesk-text" />
                <CarouselRatio imagesData={props.article_images} />
                <Useful id={props.id} title={props.title} sendAnalytics={props.sendAnalytics} />
                {props.allow_send === 1 && <Feedback id={props.id} zenId={props.dv_id} title={props.title} sendAnalytics={props.sendAnalytics} appSendFormData={props.appSendFormData} />}
            </>
        )
    }
    return (
        <>
            <Accordion key={props.id} disableGutters elevation={0} expanded={expand} onChange={() => setExpand(!expand)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => props.sendAnalytics(TypeOfAnalyticsMess.CLICK_ARTICLE, location.pathname, props.id, props.title)}
                >
                    <Typography><span className={"popular-item"}>{props.title}</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {chooseAutomatizationArticle(props.automatization_tag, props.supportData)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default Article