import '../scss/CategoryBig.scss'
import '../scss/Popular.scss'
import Back from './Back';
import OperationSystem from '../enums/os';
import ICategory from '../interfaces/ICategory';
import Article from './Article';
import TypeOfAnalyticsMess from '../enums/analytics';
import ISupportData from '../interfaces/ISupportData';

type CategoryBigPropsType = {
    title: string
    imgName: string
    categories: ICategory[] | undefined
    os: OperationSystem
    supportData?: ISupportData
    bundle_id: string | null
    locale: string | null
    app_version: string | null
    expand: string | null
    sendAnalytics: (analyticsType: TypeOfAnalyticsMess, path: string, articleId?: number, articleTitle?: string, queryBody?: string) => void
    appSendFormData: (formData: any) => void
    changeBirthDate: (field: string, value: string) => void
}

const CategoryBig = (props: CategoryBigPropsType) => {

    return (
        <div className="categoryBig-container">
            <Back os={props.os} />
            <img
                src={require(`../images/categories/big/${props.imgName}_big.png`)}
                className='banner'
                alt={props.title} />
            <div className="categoryBig-container_articles">
                {props.categories?.map((oneCategory) => {
                    return (
                        oneCategory.articles.map((oneArticle) => {
                            return (
                                <Article
                                    key={oneArticle.id}
                                    id={oneArticle.id}
                                    dv_id={oneArticle.dv_id}
                                    title={oneArticle.title}
                                    body={oneArticle.body}
                                    expand={props.expand}
                                    article_images={oneArticle.article_images}
                                    automatization_tag={oneArticle.automatization_tag}
                                    supportData={props.supportData}
                                    locale={props.locale}
                                    os={props.os}
                                    app_version={props.app_version}
                                    allow_send={oneArticle.allow_send}
                                    bundle_id={props.bundle_id}
                                    sendAnalytics={props.sendAnalytics}
                                    appSendFormData={props.appSendFormData}
                                    changeBirthDate={props.changeBirthDate} />
                            )
                        })
                    )
                })}
                {props.categories?.map((oneCategory) => {
                    return (
                        oneCategory.child.map((oneChild) => {
                            return (
                                <>
                                    <div className='subcategory'>{oneChild.name}</div>
                                    {oneChild.articles.map((oneArticle) => {
                                        return (
                                            <Article
                                                key={oneArticle.id}
                                                id={oneArticle.id}
                                                dv_id={oneArticle.dv_id}
                                                title={oneArticle.title}
                                                body={oneArticle.body}
                                                expand={props.expand}
                                                article_images={oneArticle.article_images}
                                                automatization_tag={oneArticle.automatization_tag}
                                                supportData={props.supportData}
                                                locale={props.locale}
                                                os={props.os}
                                                app_version={props.app_version}
                                                allow_send={oneArticle.allow_send}
                                                bundle_id={props.bundle_id}
                                                sendAnalytics={props.sendAnalytics}
                                                appSendFormData={props.appSendFormData}
                                                changeBirthDate={props.changeBirthDate} />
                                        )
                                    })}
                                </>
                            )
                        })
                    )
                })}
            </div>
        </div>
    )
}

export default CategoryBig