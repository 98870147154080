import '../scss/Input.scss';

type InputPropsType = {
    placeholder: string
    value: string
    filtration: (value: string) => void
}

const Input = (props: InputPropsType) => {

    return (
        <div className='input-container'>
            <img className='input-icon' src={require(`../images/shared/search.png`)} />
            <input
                type="text"
                placeholder={props.placeholder}
                value={props.value}
                className="input-custom"
                onChange={(event) => props.filtration(event.currentTarget.value)}
            />
            {props.value && <img
                className='input-icon-clear'
                src={require(`../images/shared/clear.png`)}
                onClick={(event) => props.filtration('')} />}
        </div>
    )
}

export default Input